import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {Lead} from '../../../model/Lead';
import CloseIcon from '@mui/icons-material/Close';
import {FormBuilder} from '../../../form/FormBuilder';
import {FeatureName} from '../../../../paths';
import {AppendOptionsFormFieldSchemaDecorator} from '../../../form/logic/FormDecorator';
import {difference} from '../../../form/utils';
import {FormConfig} from '../../../form/FormConfig';
import {HttpMethods} from '../../../model/HttpMethods';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface EmailDialogProps {
  isOpen: boolean;
  handleClose: Function;
  lead: Lead;
}

function EmailDialog({isOpen, handleClose, lead}: EmailDialogProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const submitEl = useRef<HTMLButtonElement>(null);
  const [initialValues, setInitialValues] = useState<any>();
  const [config, setConfig] = useState<FormConfig>();

  const [{error: hasSendEmailError, loading: isSendEmailLoading}, sendEmail] = useAxiosBFF(
    {
      url: 'emails/click-to-email',
      method: HttpMethods.POST,
    },
    {manual: true}
  );

  useEffect(() => {
    setLoading(isSendEmailLoading || isOpen, 'EmailDialog');
  }, [isSendEmailLoading, isOpen]);

  useEffect(() => {
    if (hasSendEmailError) {
      NotificationService.getInstance().sendNotification(hasSendEmailError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [hasSendEmailError]);

  useEffect(() => {
    setConfig({
      decorators: [
        new AppendOptionsFormFieldSchemaDecorator('email_to', {
          label: lead?.email || '',
          value: lead?.email || '',
        }),
      ],
    });
  }, [lead]);

  useEffect(() => {
    setInitialValues({...lead, email_to: lead.email || ''});
  }, [lead]);

  function onSubmit(data: any) {
    if (lead?.lead_id) {
      const leadId = lead?.lead_id;
      const destination = data['email_to'];
      data = difference(data, lead);

      // Ugly fix, this endpoint expects stuff that is not in the form
      data['lead_id'] = leadId;
      data['destination'] = destination;
      data['text'] = data['message'];

      delete data['message'];
      delete data['email_to'];

      sendEmail({data: data}).then((response: AxiosResponse) => {
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        }
      });
    }
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="sms-dialog-title"
        sx={{textAlign: 'center'}}
      >
        <DialogTitle id="sms-dialog-title">
          {t('leads.dialogs.email.email-a-lead')}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {lead && (
            <FormBuilder onSubmit={onSubmit} formId={FeatureName.EMAIL} initialValues={initialValues} config={config}>
              <Button ref={submitEl} type="submit" sx={{display: 'none'}}>
                {t('leads.dialogs.email.email-now')}
              </Button>
            </FormBuilder>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            id={'email-btn'}
            onClick={() => {
              submitEl?.current?.click();
            }}
          >
            {t('leads.dialogs.email.email-now')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmailDialog;
